import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`Nearby`}</h4>
    <p>{`The "nearby" tab shows other activities of the same category close to the
one opened.`}</p>
    <Image src="features/activitypagenearby.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Reviews`}</h4>
    <p>{`Shows the latest reviews for this activity.`}</p>
    <br />
    <p>{`The top part of the page shows a bar chart with a summary of all reviews and
reported difficulty ratings followed by the individual reviews and comments.`}</p>
    <br />
    <p>{`The ratings go from 1 (bad) to 5 (epic). `}<Link to="/features/reviews" mdxType="Link">{`See how you can review a trail here.`}</Link></p>
    <Image src="features/activitypagereviews.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Conditions`}</h4>
    <p>{`Shows the latest condition reports for this activity.`}</p>
    <br />
    <p>{`The top part of the page shows a bar chart with a summary of all condition
reports followed by the individual reports. The ratings go from 1 (bad) to 5
(perfect).`}</p>
    <br />
    <p>{`Make sure you check the date of the conditon reports. The conditions can
change quickly.`}</p>
    <Image src="features/activitypageconditions.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <Link className="float-left" to="/features/activitypage" mdxType="Link">
  ← Activity page
    </Link>
    <Link className="float-right" to="/features/activitybuttons" mdxType="Link">
  Action buttons →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      